export default class HateItem {
  constructor(_el) {
    this.el = _el;
    this.itemCount = 5;
    this.elements = [];
    this.draw();
  }
  draw() {
    this.el.innerHTML = '';
    this.elements = [];
    for (let i = 0; i < this.itemCount; i++) {
      setTimeout(() => {
        this.drawElement();
      }, i * 100);
    }
    setTimeout(() => {
      this.draw();
    }, 3000);
  }
  drawElement(i) {
    let ele = document.createElement('i');
    let sX = Math.random() + .1;
    let sY = (Math.random()*1.5) + 0.5;
    let rot = Math.round(Math.random() * 360);
    let posX = (Math.random() * 3) - 1.5;
    let posY = (Math.random() * 3) - 1.5;
    ele.style.transform = `translate(${posX}rem, ${posY}rem) rotate(${rot}deg) scale(${sX}, ${sY})`;
    this.el.appendChild(ele);
    this.elements.push(ele);
  }
}
