import "../scss/main.scss";

import Home from "./Home";
import HomeDog from "./HomeDog";
import HateLine from "./HateLine";
import HateItem from "./HateItem";
import PieChart from "./PieChart";
import Faq from "./Faq";
import MeldenModal from "./MeldenModal";

if (document.body.classList.contains("home")) {
  const home = new Home();
}

const lines = document.querySelectorAll(".hate-line");
for (let i = 0; i < lines.length; i++) {
  let hateLine = new HateLine(lines[i]);
}
const hates = document.querySelectorAll(".divider-hate");
for (let i = 0; i < hates.length; i++) {
  let hateItem = new HateItem(hates[i]);
}
const pies = document.querySelectorAll(".piechart__chart");
for (let i = 0; i < pies.length; i++) {
  let pieChart = new PieChart(pies[i]);
}
const dogAnim = document.querySelector("#botdog-anim");
if (dogAnim) {
  let homeDog = new HomeDog(dogAnim);
}
const faqContent = document.querySelector(".faq-content");
if (faqContent) {
  let faq = new Faq(faqContent);
}
const meldenLinks = document.querySelectorAll(".js-openMeldenModal");
if (meldenLinks.length > 0) {
  new MeldenModal(meldenLinks);
}

const menuBtn = document.querySelector("#js-toggleMenu");
const header = document.querySelector(".header");
menuBtn.addEventListener("click", (e) => {
  e.preventDefault();
  header.classList.toggle("show-menu");
});
