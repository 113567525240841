import Chart from 'chart.js';

export default class PieChart {
  constructor(_el) {
    this.el = _el;
    this.ctx = this.el.querySelector('.chart').getContext('2d');

    this.data = JSON.parse(this.el.dataset.chartdata);
    this.drawChart();
  }
  drawChart() {
    const colors = [
      '#29265B',
      '#E61D73',
      // '#F4F4F4',
      '#9B9886',
      '#90FFDC',
      '#F75C03',
      '#42CAFD',
      '#C3F73A',
      '#48A9A6',
      '#B4ADEA'
    ];
    let data = {
      datasets: [],
      labels: [],
    };
    data.datasets[0] = {
      data: [],
      backgroundColor: colors,
    };
    this.data.forEach((d) => {
      data.datasets[0].data.push(d.field_itemPercentage);
      data.labels.push(d.field_itemTitle);
    });
    let options = {
      legend: {
        position: 'bottom',
      },
    };
    var myPieChart = new Chart(this.ctx, {
      type: 'doughnut',
      data: data,
      options: options,
    });
  }
}
