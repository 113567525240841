import { SVG } from '@svgdotjs/svg.js';

export default class HateLine {
  constructor(_el) {
    this.h = 5;
    this.x = 0;
    this.el = _el;
    this.draw = SVG(this.el);
    this.draw.viewbox(0, 0, 450, 5);
    this.drawLine();
  }
  drawLine() {
    for (let i = 0; i < 40; i++) {
      this.drawElement(i);
    }
  }
  drawElement(i) {
    let rand = Math.floor(Math.random() * 2);
    let w = 0;

    if (i % 2 == 0) {
      w = Math.floor(Math.random() * 30) + 3;
      this.draw.rect(w, this.h).move(this.x, 0).fill('#29265B');
    } else {
      w = Math.floor(Math.random() * 20) + 5;
    }

    this.x += w;
  }
}
