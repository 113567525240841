import axios from "axios";

export default class MeldenModal {
  constructor(links) {
    this.links = links;
    this.modal = document.querySelector("#melden-modal");
    this.form = document.querySelector("#melden-form");
    this.response = document.querySelector("#melden-response");
    this.initLinks();
    this.initModal();
  }
  initLinks() {
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener("click", (e) => {
        e.preventDefault();
        this.openModal();
      });
    }
  }
  initModal() {
    const closeButton = this.modal.querySelector(".melden-modal__close");
    closeButton.addEventListener("click", (e) => {
      this.closeModal();
    });
    this.modal.addEventListener("click", (e) => {
      if(e.target !== e.currentTarget) return;
      this.closeModal();
    });
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      let data = new FormData(this.form);
      let obj = {};
      for (let [key, value] of data) {
        obj[key] = value;
      }
      this.form.querySelector('button').setAttribute('disabled', 'disabled');
      axios
        .post("https://shsapitest.azurewebsites.net/api/ReportedPublic", obj)
        .then((data) => {
          console.log(data);
          this.response.innerText = 'Vielen Dank!'
        })
        .catch((error) => {
          console.log(error);
          this.response.innerText = 'Error'
        });
    });
  }
  openModal() {
    this.modal.classList.add("melden-modal--visible");
  }
  closeModal() {
    this.modal.classList.remove("melden-modal--visible");
  }
}
