export default class Home {
  constructor() {
    this.header = document.querySelector('.header');
    this.hero = document.querySelector('.home__hero');
    this.initHeader();
  }
  initHeader() {
    this.header.classList.add('header--inv');
    let observer = new IntersectionObserver(
      (e) => {
        if (e[0].intersectionRatio > 0) {
          this.header.classList.add('header--inv');
        } else {
          this.header.classList.remove('header--inv');
        }
      },
      {
        threshold: 0,
      }
    );
    observer.observe(this.hero);
  }
}
