import lottie from 'lottie-web';

export default class HomeDog {
  constructor(_el) {
    this.el = _el;
    this.initAnim();
  }
  initAnim() {
    if (!this.el) return;

    lottie.loadAnimation({
      container: this.el,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/dog-anim.json',
      useSubFrames: false,
    });
  }
}
